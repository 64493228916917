import './produtos.css'

function Produtos(){
  return(
    <div className='page-produtos'>
      <h1>Para Construtoras</h1>
      <div className='produtos-construtora'>
        <div className='arame-r-c-t'><a href='/produtos/arame-r-c-t'></a><div><h3>Arame Recozido Comercial Torcido</h3></div></div>
        <div className='arame-r-c-l'><a href='/produtos/arame-r-c-l'></a><div><h3>Arame Recozido Comercial Liso</h3></div></div>
        <div className='arame-r-i'><a href='/produtos/arame-r-i'></a><div><h3>Arame Recozido Industrial</h3></div></div>
        <div className='barra-ca-25'><a href='/produtos/barra-ca-25'></a><div><h3>Barra CA-25</h3></div></div>
        <div className='barra-ca-50'><a href='/produtos/barra-ca-50'></a><div><h3>Barra CA-50</h3></div></div>
        <div className='barra-ca-60'><a href='/produtos/barra-ca-60'></a><div><h3>Barra CA-60</h3></div></div>
        <div className='barra-transferencia'><a href='/produtos/barra-transferencia'></a><div><h3>Barra de Transferência</h3></div></div>
        <div className='coluna'><a href='/produtos/coluna'></a><div><h3>Coluna</h3></div></div>
        <div className='estribo'><a href='/produtos/estribo'></a><div><h3>Estribo</h3></div></div>
        <div className='prego-d-c-c'><a href='/produtos/prego-d-c-c'></a><div><h3>Prego Cabeça Comum</h3></div></div>
        <div className='prego-d-c-d'><a href='/produtos/prego-d-c-d'></a><div><h3>Prego Cabeça Dupla</h3></div></div>
        <div className='prego-t'><a href='/produtos/prego-t'></a><div><h3>Prego Telheiro</h3></div></div>
        <div className='tela'><a href='/produtos/tela'></a><div><h3>Tela</h3></div></div>
        <div className='trelica'><a href='/produtos/trelica'></a><div><h3>Treliça</h3></div></div>
      </div>
      <h1>Para a Indústria</h1>
      <div className='produtos-industria'>
        <div className='arame-btc'><a href='/produtos/arame-btc'></a><div><h3>Arame BTC</h3></div></div>
        <div className='ca-60-r'><a href='/produtos/ca-60'></a><div><h3>CA-60 Aramebras</h3></div></div>
      </div>
    </div>
  )
}

export default Produtos;