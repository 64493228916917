import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Produtos from './pages/Produtos';
import Catalogo from './pages/Catalogo';
import Contato from './pages/Contato'
import ArameBTC from './pages/Produtos/ArameBTC'
import ArameRCL from './pages/Produtos/ArameRCL'
import ArameRCT from './pages/Produtos/ArameRCT'
import ArameRI from './pages/Produtos/ArameRI'
import BarraCA25 from './pages/Produtos/BarraCA25'
import BarraCA50 from './pages/Produtos/BarraCA50'
import BarraCA60 from './pages/Produtos/BarraCA60'
import BarraTransferencia from './pages/Produtos/BarraTransferencia'
import CA60R from './pages/Produtos/CA60R'
import Coluna from './pages/Produtos/Coluna'
import Estribo from './pages/Produtos/Estribo'
import PregoDCC from './pages/Produtos/PregoDCC'
import PregoDCD from './pages/Produtos/PregoDCD'
import PregoT from './pages/Produtos/PregoT'
import Tela from './pages/Produtos/Tela'
import Trelica from './pages/Produtos/Trelica'


import Header from "./components/Header";
import WhatsApp from './components/WhatsApp'
import Footer from './components/Footer'

function RoutesApp(){
  return(
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" element={ <Home/> }/>
        <Route path="/sobre" element={ <Sobre/> }/>
        <Route path="/produtos" element={ <Produtos/> }/>
        <Route path="/catalogo" element={ <Catalogo/> }/>
        <Route path="/contato" element={ <Contato/> }/>
        <Route path="/produtos/arame-btc" element={ <ArameBTC/> }/>
        <Route path="/produtos/arame-r-c-l" element={ <ArameRCL/> }/>
        <Route path="/produtos/arame-r-c-t" element={ <ArameRCT/> }/>
        <Route path="/produtos/arame-r-i" element={ <ArameRI/> }/>
        <Route path="/produtos/barra-ca-25" element={ <BarraCA25/> }/>
        <Route path="/produtos/barra-ca-50" element={ <BarraCA50/> }/>
        <Route path="/produtos/barra-ca-60" element={ <BarraCA60/> }/>
        <Route path="/produtos/barra-transferencia" element={ <BarraTransferencia/> }/>
        <Route path="/produtos/coluna" element={ <Coluna/> }/>
        <Route path="/produtos/estribo" element={ <Estribo/> }/>
        <Route path="/produtos/prego-d-c-c" element={ <PregoDCC/> }/>
        <Route path="/produtos/prego-d-c-d" element={ <PregoDCD/> }/>
        <Route path="/produtos/prego-t" element={ <PregoT/> }/>
        <Route path="/produtos/tela" element={ <Tela/> }/>
        <Route path="/produtos/trelica" element={ <Trelica/> }/>
        <Route path="/produtos/ca-60" element={ <CA60R/> }/>
      </Routes>
      <WhatsApp/>
      <Footer/>
    </BrowserRouter>
  )
}

export default RoutesApp;