import './barratransferencia.css'
import ImgProduto from '../../../assets/produtos/img-produtos-barra-transferencia-1-min.png'

function BarraTransferencia(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Barra de Transferência</h1>
          <p>A barra de transferência Aramebras é produzida com aço CA-25, permitindo movimentações horizontais de dilatação e retração entre as placas de concreto.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Maior segurança à obra;</p>
          <p>Facilidade de aplicação;</p>
          <p>Facilidade de manuseio;</p>
          <p>Economia na obra;</p>
          <p>Benefício estrutural ao concreto.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Pisos;</p>
          <p>Pavimentos de concreto;</p>
          <p>Lajes.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>10,00mm (3/8);</p>
          <p>12,50mm (1/2);</p>
          <p>16,00mm (5/8);</p>
          <p>20,00mm (3/4);</p>
          <p>25,00mm (1”);</p>
          <p>32,00mm (1 1/4).</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default BarraTransferencia;