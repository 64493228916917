import './pregodcc.css'
import ImgProduto from '../../../assets/produtos/img-produtos-prego-d-c-c-1-min.png'

function PregoDCC(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Prego de Cabeça Comum</h1>
          <p>Os pregos comuns Aramebras são fabricados com aço de baixo teor de carbono (BTC) conforme a norma ABNT NBR 5589 e comercializados em caixas com 20 pacotes de 1kg.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Baixo desperdício de pregos;</p>
          <p>Alta resistência mecânica.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Uso para fixação geral em marcenaria, uso doméstico, construção civil, entre outros.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>8×8; 10×10; 11×11; 12×12; 12×15; 13×15; 13×18; 13×21; 14×18; 14×21;</p>
          <p>15×15; 15×18; 15×21; 15×27; 16×18; 16×21; 16×24; 16×27; 17×21; 17×24;</p>
          <p>17×27; 17×30; 18×21; 18×24; 18×27; 18×30; 18×33; 18×36; 19×27;</p>
          <p>19×30; 19×36; 19×39; 20×30; 20×42; 20×48; 22×42; 22×54; 23×60;</p>
          <p>25×72; 26×84.</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default PregoDCC;