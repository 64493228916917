import './estribo.css'
import ImgProduto from '../../../assets/produtos/img-produtos-estribos-1-min.png'

function Estribo(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Estribos</h1>
          <p>Os estribos Aramebras são fabricados em fio de aço CA-60 nervurado garantindo maior resistência e aderência ao concreto.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Facilidade de utilização</p>
          <p>Maior rapidez na obra</p>
          <p>Redução de cortes e dobras</p>
          <p>Redução de perdas de material</p>
          <p>Dimensões das dobras padronizadas</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Vigas;</p>
          <p>Colunas;</p>
          <p>Pilares;</p>
          <p>Sapatas;</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>7×12; 7×14; 7×17; 7×20; 7×22; 7×25; 7×27; 9×15; 9×20; 10×10; 10×15; 10×20; 10×25;
12×12; 12×17; 12×22; 12×27; 15×15; 15×20; 15×25; 17×17; 17×27.</p>
          <p>Dimensões especiais sob consulta</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default Estribo;