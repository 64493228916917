import './coluna.css'
import ImgProduto from '../../../assets/produtos/img-produtos-coluna-1-min.png'

function Coluna(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Coluna</h1>
          <p>A coluna Aramebras é fabricada com vergalhão CA-50 e estribo CA-60 nervurados e soldados entre si, que confere uma melhor aderência do produto ao concreto. Desse modo, as colunas são capazes de realizar um excelente travamento para manter a estrutura da obra.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Excelente sustentação de vigas, colunas de concreto, cintas, baldrames e etc;</p>
          <p>Agilidade na execução e na organização da obra;</p>
          <p>Redução de custos e tempo de construção;</p>
          <p>Melhor eficiência na padronização das dimensões nos projetos.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Vigas;</p>
          <p>Cintas;</p>
          <p>Baldrames;</p>
          <p>Muros;</p>
          <p>Vergas e Contravergas.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>7×14 6,30mm</p>
          <p>7×14 8,00mm</p>
          <p>7×17 8,00mm</p>
          <p>10×20 10mm</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default Coluna;