import './barraca50.css'
import ImgProduto from '../../../assets/produtos/img-produtos-barra-ca-50-4-min.png'

function BarraCA50(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Barra CA-50</h1>
          <p>A barra CA-50 fornecida pela Aramebras são produzidas em usinas através do processo de laminação a quente e conforme a norma ABNT NBR 7480, proporcionando maior segurança à estrutura. As barras possuem nervuras para melhor aderência ao concreto.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Facilidade de solda;</p>
          <p>Melhor aderência ao concreto;</p>
          <p>Capacidade de solda topo.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Pisos industriais;</p>
          <p>Pavimentos de concreto;</p>
          <p>Armaduras pré-moldadas;</p>
          <p>Estrutura de concreto armado para casas e edifícios.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>6,30mm (1/4)</p>
          <p>8,00mm (5/16)</p>
          <p>10,00mm (3/8)</p>
          <p>2,50mm (1/2)</p>
          <p>16,00mm (5/8)</p>
          <p>20,00mm (3/4)</p>
          <p>25,00mm (1”)</p>
          <p>32,00mm (1 1/4)</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default BarraCA50;