import './tela.css'
import ImgProduto from '../../../assets/produtos/img-produtos-tela-1-min.png'

function Tela(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Tela</h1>
          <p>A tela eletrossoldada Aramebras é fabricada com fios de aço CA-60 nervurados, transversais e longitudinais, soldados entre si em todos os pontos cruzados através de correntes elétricas. Os fios são produzidos por laminação a frio, com matéria-prima de alta qualidade.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Proporciona durabilidade aos pisos;</p>
          <p>Baixa manutenção;</p>
          <p>Rapidez na montagem;</p>
          <p>Possui alta aderência ao concreto.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Pisos;</p>
          <p>Lajes;</p>
          <p>Pontes e viadutos;</p>
          <p>Paredes;</p>
          <p>Pisos;</p>
          <p>Pavimentos de concreto estrutural;</p>
          <p>Galerias.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p></p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default Tela;