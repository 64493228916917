import { Link } from 'react-router-dom';
import './header.css'
import LogoHeader1 from '../../assets/logo-header-1.png'
import LogoHeader2 from '../../assets/logo-header-2.png'

function Header(){
  return(
    <header>
      <div className='logo'>
        <img className='logo-img' src={LogoHeader1} alt='logo-aramebras'></img>
      </div>
      <div className='menu'>
        <div className='menu-left'>
          <Link className='header-menu' to='/'>Home</Link>
          <Link className='header-menu' to='/sobre'>Sobre</Link>
          <Link className='header-menu' to='/produtos'>Produtos</Link>
        </div>
        <img className='logo-central' src={LogoHeader2} alt='logo-central'></img>
        <div className='menu-right'>
          <Link className='header-menu' to='/catalogo'>Catálogo</Link>
          <Link className='header-menu' to='/contato'>Contato</Link>
        </div>
      </div>
    </header>
  )
}

export default Header;