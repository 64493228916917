import './trelica.css'
import ImgProduto from '../../../assets/produtos/img-produtos-trelica-1-min.png'

function Trelica(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Treliça</h1>
          <p>A Treliça Aramebras é fabricada com aço CA-60 nervurado, que confere melhor aderência do produto ao concreto. Desse modo, as treliças têm alta capacidade de vencer grandes vãos e suportar cargas elevadas com toda a segurança.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Mais qualidade e segurança na obra;</p>
          <p>Redução do custo com mão-de-obra;</p>
          <p>Racionalização na execução e na organização do canteiro de obras;</p>
          <p>Maior rapidez na montagem da sua estrutura;</p>
          <p>Menor risco de fissuras no concreto.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Lajes pré-moldadas treliçadas;</p>
          <p>Lajes planas e minipainéis treliçados;</p>
          <p>Lajes treliçadas autoportantes;</p>
          <p>Espaçador de armaduras (de 6 a 25 cm de altura).</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>H4; H5 (somente para uso como espaçador treliçado e para posicionar armaduras, telas ou barras) H6; H8; H10; H12; H14; H16; H20; H25.</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default Trelica;