import './pregodcd.css'
import ImgProduto from '../../../assets/produtos/img-produtos-prego-d-c-d-1-min.png'

function PregoDCD(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Prego de Cabeça Dupla</h1>
          <p>Os pregos cabeça dupla Aramebras são fabricados com aço de baixo teor de carbono (BTC) conforme a norma ABNT NBR 5589 e comercializados em caixas com 20 pacotes de 1kg.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Maior velocidade na desforma;</p>
          <p>Menor desperdício de pregos e materiais;</p>
          <p>Agilidade na mão de obra.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Fechamento de formas (fundações, escadas, pilares);</p>
          <p>Escoramento de lajes.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>17×27; 18×30; 18×36.</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default PregoDCD;