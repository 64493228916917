import './barraca60.css'
import ImgProduto from '../../../assets/produtos/img-produtos-barra-ca-60-4-min-1.png'

function BarraCA60(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Barra CA-60</h1>
          <p>A barra CA-60 Aramebras é fabricada com nervuras, conferindo melhor aderência ao concreto. As barras são produzidas conforme a norma ABNT NBR 7480, garantindo máxima segurança para construção de estruturas de concreto armado.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Torna a estrutura de concreto mais leve;</p>
          <p>Possui alta resistência;</p>
          <p>Maior facilidade de solda.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Telas eletrossoldadas;</p>
          <p>Colunas;</p>
          <p>Estribos;</p>
          <p>Treliças.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>4,20mm</p>
          <p>5,00mm</p>
          <p>6,00mm</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default BarraCA60;