import { useState } from 'react';
import './contato.css'
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Contato(){
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  function sendEmail(e){
    e.preventDefault();

    if(name === '' || email === '' || subject === '' || message === ''){
      alert('É necessário preencher todos os campos!')
      return;
    }

    const templateParams = {
      from_name: name,
      email: email,
      subject: subject,
      message: message
    }

    emailjs.send('service_zx22xn6', 'template_fgdfwn2', templateParams, 'f15IxyIJ7zJRXii1K')
    .then((response) => {
      console.log('Email enviado', response.status)
      setName('')
      setEmail('')
      setSubject('')
      setMessage('')
    }, (err) => {
      console.log(`Erro: ${err}`);
    })
  }

  return (
    <div className='contact'>
      <div className='contact-content'>
        <h1>Entre em contato</h1>
        <p>Solicite seu orçamento ou esclareça qualquer dúvida pelos nossos canais de comunicação.</p>
        <div className='contact-phone'>
          <div>
            <FontAwesomeIcon className='faPhone' icon={faPhone} />
          </div>
          <p>(41) 3515-6711</p>
        </div>
        <div className='contact-location'>
          <div>
            <FontAwesomeIcon className='faLocationDot' icon={faLocationDot} />
          </div>
          <p>R. Semíramis de Macedo Seiler, 326 - Cidade Industrial De Curitiba - PR, CEP 81250-370</p>
        </div>
        <div className='contact-email'>
          <div>
            <FontAwesomeIcon className='faEnvelope' icon={faEnvelope} />
          </div>
          <p>comercial@aramebras.com.br</p>
        </div>
      </div>
      <form className='contact-form' onSubmit={sendEmail}>
        <input 
          type='text' 
          name='nome' 
          placeholder='Nome'
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoComplete='none'
        />
        <input 
          type='email' 
          name='email' 
          placeholder='E-Mail'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <input 
          type='text' 
          name='assunto' 
          placeholder='Assunto'
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
        />
        <textarea 
          rows='10' 
          cols='40' 
          placeholder='Mensagem'
          onChange={(e) => setMessage(e.target.value)}
          value={message}
        />
        <input 
          className='send-email-button'
          type='submit'
          value='Enviar'
        />
      </form>
    </div>
  )
}

export default Contato;