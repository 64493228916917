import './slider.css'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { register } from 'swiper/element/bundle';
import 'swiper/css';

import Img1 from '../../assets/produtos/img-produto-arame-r-c-t-1-min.png'
import Img2 from '../../assets/produtos/img-produtos-arame-r-c-l-1-min.png'
import Img3 from '../../assets/produtos/img-produtos-arame-r-i-1-min.png'
import Img4 from '../../assets/produtos/img-produtos-barra-ca-25-1-min.png'
import Img5 from '../../assets/produtos/img-produtos-barra-ca-50-4-min.png'
import Img6 from '../../assets/produtos/img-produtos-barra-ca-60-4-min-1.png'
import Img7 from '../../assets/produtos/img-produtos-barra-transferencia-1-min.png'
import Img8 from '../../assets/produtos/img-produtos-ca-60-r-1-min.png'
import Img9 from '../../assets/produtos/img-produtos-coluna-1-min.png'
import Img10 from '../../assets/produtos/img-produtos-estribos-1-min.png'
import Img11 from '../../assets/produtos/img-produtos-prego-d-c-c-1-min.png'
import Img12 from '../../assets/produtos/img-produtos-prego-d-c-d-1-min.png'
import Img13 from '../../assets/produtos/img-produtos-prego-t-1-min.png'
import Img14 from '../../assets/produtos/img-produtos-tela-1-min.png'
import Img15 from '../../assets/produtos/img-produtos-trelica-1-min.png'
import Img16 from '../../assets/produtos/img-produtos-arame-btc-4-min.png'
register();

function Slider(){

  const data = [
    { id: '1', image: Img1, src: '/produtos', name: 'Arame Recozido Comercial Torcido' },
    { id: '2', image: Img2, src: '/produtos', name: 'Arame Recozido Comercial Liso' },
    { id: '3', image: Img3, src: '/produtos', name: 'Arame Recozido Industrial' },
    { id: '4', image: Img4, src: '/produtos', name: 'Barra CA-25' },
    { id: '5', image: Img5, src: '/produtos', name: 'Barra CA-50' },
    { id: '6', image: Img6, src: '/produtos', name: 'Barra CA-60' },
    { id: '7', image: Img7, src: '/produtos', name: 'Barra de Transferência' },
    { id: '8', image: Img8, src: '/produtos', name: 'CA-60 Aramebras' },
    { id: '9', image: Img9, src: '/produtos', name: 'Coluna' },
    { id: '10', image: Img10, src: '/produtos', name: 'Estribos' },
    { id: '11', image: Img11, src: '/produtos', name: 'Prego Cabeça Comum' },
    { id: '12', image: Img12, src: '/produtos', name: 'Prego Cabeça Dupla' },
    { id: '13', image: Img13, src: '/produtos', name: 'Prego Telheiro' },
    { id: '14', image: Img14, src: '/produtos', name: 'Tela' },
    { id: '15', image: Img15, src: '/produtos', name: 'Treliça' },
    { id: '16', image: Img16, src: '/produtos', name: 'Arame BTC' }
  ]

  return(
    <div className='slider'>
      <Swiper
      slidesPerView={4}
      navigation
      autoplay
      loop
      spaceBetween={30}
      >     
        {data.map( ( item ) => (
          <SwiperSlide key={item.id}>
            <Link to={item.src} target='blank'>
              <img
                className='slide-item'
                src={item.image}
                alt='Produtos'
              />
            </Link>
            <p className='item-description'>{item.name}</p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Slider;