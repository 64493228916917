import './pregot.css'
import ImgProduto from '../../../assets/produtos/img-produtos-prego-t-1-min.png'

function PregoT(){
  return(
    <div className="product-content">
      <div className='product-header'>
        <img src={ImgProduto} alt='imagem-produto'/>
        <div>
          <h1>Prego Telheiro</h1>
          <p>Os pregos telheiro Aramebras são fabricados com aço de baixo teor de carbono (BTC) galvanizado, com o corpo ardox, borracha flexível e arruela metálica. São comercializados em sacos com 50 pacotes contendo 30 unidades de prego em cada.</p>
        </div>
      </div>
      <div className='product-detail'>
        <div>
          <h2>Benefícios do Produto</h2>
          <p>Proteção contra corrosão do prego, devido à galvanização;</p>
          <p>Borracha não resseca com o tempo;</p>
          <p>Excelente fixação em telhas.</p>
        </div>
        <div>
          <h2>Aplicações Recomendadas</h2>
          <p>Aplicação em telhas de alumínio;</p>
          <p>Aplicação em telhas de fibrocimento.</p>
        </div>
        <div>
          <h2>Especificações</h2>
          <p>18×30; 18×36.</p>
        </div>
      </div>
      <div className='budget-button'>
        <a href="/contato">Solicitar orçamento</a>
      </div>
    </div>
  )
}

export default PregoT;