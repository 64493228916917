import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import ImgFooter from '../../assets/img-footer.png'
import './footer.css'

function Footer(){
  return(
    <footer>
      <div className='footer-content'>
        <div className='footer-phone'>
          <div>
            <FontAwesomeIcon className='faPhone' icon={faPhone} />
          </div>
          <p>(41) 3515-6711</p>
        </div>
        <div className='footer-location'>
          <div>
            <FontAwesomeIcon className='faLocationDot' icon={faLocationDot} />
          </div>
          <p>R. Semíramis de Macedo Seiler, 326 - Cidade Industrial De Curitiba - PR, CEP 81250-370</p>
        </div>
        <div className='footer-email'>
          <div>
            <FontAwesomeIcon className='faEnvelope' icon={faEnvelope} />
          </div>
          <p>comercial@aramebras.com.br</p>
        </div>
      </div>
      <img className='img-footer' src={ImgFooter} alt='imagem-footer'></img>
    </footer>
  )
}

export default Footer;