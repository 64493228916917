import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import './whatsapp.css'

function WhatsApp(){
  return(
    <div className='whatsapp'>
      <a className='whatsapp-button' href='https://api.whatsapp.com/send/?phone=554135156711' target='blank'><FontAwesomeIcon icon={faWhatsapp} /></a>      
    </div>
  )
}

export default WhatsApp;