import './catalogo.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import ImgCatalogo from '../../assets/img-catalogo.png'
import CatalogoConstrutora from '../../assets/catalogos/catalogo_aramebras_para_construtora.pdf'
import CatalogoIndustria from '../../assets/catalogos/catalogo_aramebras_para_industria.pdf'

function Catalogo(){
  return(
    <div className='catalogo'>
      <img className='img-catalogo' src={ImgCatalogo} alt='img-catalogo'/>
      <div className='content-catalogo'>
        <h1>Conheça nossos produtos</h1>
        <p>Com os materiais que produzimos, é possível reduzir o tempo de execução de atividades e aumentar a produtividade na construção.</p>
        <p>Confira nossas soluções no nosso catálogo.</p>
        <a href={CatalogoConstrutora} download><FontAwesomeIcon icon={faDownload} /> Catálogo Para Construtoras</a>
        <a href={CatalogoIndustria} download><FontAwesomeIcon icon={faDownload} /> Catálogo Para Indústrias</a>
      </div>
    </div>
  )
}

export default Catalogo;